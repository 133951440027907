import React from "react"
import Layout from "../components/layout"
import TwoColumnList from "../components/twoColumnList"
import Article from "../components/article"
import SEO from '../components/SEO/seo'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default () => {
    let skills = new Map([
      ["web design",5],
      ["web development",5],
      ["object oriented programming",5],
      ["functional programming",5],
      ["Python",5],
      ["ReactJS",5],
      ["JavaScript",4],
      ["Ruby",3],
      ["Java",4],
      ["SQL",4],
      ["C++",3],
      ["SQL",4],
      ["Linux",4],
      ["Bash",4],
      ["JQuery",4],
      ["Bottle",4],
      ["Flask",4],
      ["Alchemy",3],
      ["Ruby on Rail",3],
      ["SEO",4],
      ["Scrum",4],
    ])
    return (
      <Layout>
        <SEO title="Tri Chu - Skills" description="here is a list of what Tri Chu can do to help"></SEO>
        <Article>
          <p className="bold">What I Can Do</p>
          <p>
            Software development can be a daunting process, especially if you
            are not familiar with the different options available. Here are
            something I can do to help ease the pain.
          </p>
        </Article>
        <Article>
          <Row>
            <Col md={8} sm={12}>
              <p className="bold">Solve Your Problem</p>
              <p>
                I can take your problems and specifications and break them down
                into steps and solutions.
              </p>
            </Col>
            <Col className="center-content" md={4} sm={12}>
                <img src={'/rocket.png'} alt=""/>
            </Col>
          </Row>
        </Article>
        <Article>
          <Row>
            <Col className="center-content" md={4} sm={12}>
                <img src={'/spanner.png'} alt=""/>
            </Col>
            <Col md={8} sm={12}>
              <p className="bold">Design Your System</p>
              <p>
                I can put together systems with moving parts that suit your need
                and scalable.
              </p>
            </Col>
          </Row>
        </Article>
        <Article>
          <Row>
            <Col md={8} sm={12}>
              <p className="bold">Develop What You Need</p>
              <p>
                I can help you to select the right technology stack that
                facilitates fast development and is maintainable for any
                project.
              </p>
            </Col>
            <Col className="center-content" md={4} sm={12}>
                <img src={'/focus_len.png'} alt=""/>
            </Col>
          </Row>
        </Article>
        <Article>
          <h3 id="skills">Abilities</h3>
          <div>
            <TwoColumnList listValues={skills} />
          </div>
        </Article>
      </Layout>
    )
}