import React from "react"
import Skillbar from "./skillbar"
import {ulClass, liClass} from "./twoColumnList.module.css"

export default ({listValues}) => {
    let liList = []
    listValues.forEach((value, key) => { 
      liList.push(<li key={"ability#" + key} className={liClass}>
        <span>{key}</span><Skillbar score={value}></Skillbar>
      </li>)
    })
    return (
        <ul className={ulClass}>
            {liList}
        </ul>
    )
}